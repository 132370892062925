import { Controller } from '@hotwired/stimulus'

const REGULAR = 'regular'
const PREPAID = 'prepaid'

const isPrepaid = (value) => value === 'true'

export default class extends Controller {
  static targets = [
    'shippingMethod',
    'regularMethodsButton',
    'prepaidMethodsButton',
    'optionsSection',
    'showOptionsButton'
  ]
  static classes = ['activeButton', 'inactiveButton', 'visible', 'hidden']

  // Hide options if their uploaded label was refused
  showShippingOptions(event) {
    event.preventDefault()
    this.optionsSectionTarget.classList.remove(this.hiddenClass)
    this.optionsSectionTarget.classList.add(this.visibleClass)
    this.showOptionsButtonTarget.classList.add(this.hiddenClass)
  }

  // The shipping methods will be automatically filtered out if the regular button is displayed
  regularMethodsButtonTargetConnected() {
    this.filterShippingMethods(REGULAR)
  }

  clickOnFilterRegularMethods(event) {
    event.preventDefault()

    this.activateButton(this.regularMethodsButtonTarget)
    this.deactivateButton(this.prepaidMethodsButtonTarget)

    this.filterShippingMethods(REGULAR)
  }

  clickOnFilterPrepaidMethods(event) {
    event.preventDefault()

    this.activateButton(this.prepaidMethodsButtonTarget)
    this.deactivateButton(this.regularMethodsButtonTarget)

    this.filterShippingMethods(PREPAID)
  }

  activateButton(button) {
    button.classList.add(...this.activeButtonClasses)
    button.classList.remove(this.inactiveButtonClass)
  }

  deactivateButton(button) {
    button.classList.add(this.inactiveButtonClass)
    button.classList.remove(...this.activeButtonClasses)
  }

  showShippingMethod(method) {
    method.classList.add(this.visibleClass)
    method.classList.remove(this.hiddenClass)
  }

  hideShippingMethod(method) {
    method.classList.add(this.hiddenClass)
    method.classList.remove(this.visibleClass)
  }

  filterShippingMethods(paymentType) {
    this.shippingMethodTargets.forEach((el) => {
      if (paymentType === PREPAID) {
        isPrepaid(el.dataset.isPrepaid)
          ? this.showShippingMethod(el)
          : this.hideShippingMethod(el)
      } else {
        // means 'regular'
        isPrepaid(el.dataset.isPrepaid)
          ? this.hideShippingMethod(el)
          : this.showShippingMethod(el)
      }
    })
  }
}
